export default class ExtranetSharedFilesIndex {

  constructor() {
      this.bindEvents();
  }


  bindEvents() {
    $("#files-datatable").DataTable({
      dom: 'rtp',
      order: [[0, 'desc']],
      language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
  }
}