import TemplateValidation from "../utils/TemplateValidation";

export default class ExtranetOrdersNew {
    constructor() {
        this.validation_client = null;
        this.validation_technical = null;

        this.initValidation();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $(".next").click(this.onNext);
        $(".prev").click(this.onPrev);

        let $select2 = $("#options_select")
        $select2.select2({
            multiple: true,
            closeOnSelect: false
        });
        $select2.val("")
        $select2.trigger("change");
        $select2.change(this.onOptionChanged);

        $("#quote_client_form input").change(this.onInputChanged);
        $("#quote_technical_form input").change(this.onInputChanged);
        $("#quote_client_form select").change(this.onInputChanged);
        $("#quote_technical_form select").change(this.onInputChanged);

        $("#quote_client_form").on("submit", this.onSubmit);
        $("#quote_technical_form").on("submit", this.onSubmit);
        $('a[data-toggle="tab"]').on("shown.bs.tab", this.validateForm);
        $(window).keydown(this.preventEnterKey);
        $("#delivery_interface").change(this.onDeliveryInterfaceChanged);

        $("#submit_quote").click(this.onSubmitQuote);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $("#options_select").select2("destroy");

        if(this.validation_client !== undefined && this.validation_client !== null){
            this.validation_client.destroy();
        }

        if(this.validation_technical !== undefined && this.validation_technical !== null){
            this.validation_technical.destroy();
        }
    }

    onNext(e) {
        let self = application.current_handler;
        self.moveToTab($(e.target).data("target"));
    }

    onPrev(e) {
        let self = application.current_handler;
        self.moveToTab($(e.target).data("target"));
    }

    moveToTab(id){
      $("a[href='"+id+"']").tab("show");
    }

    loadSummary(){
      console.log("loadSummary");
    }

    onOptionChanged(e){
        let self = application.current_handler;
        let options = $("#options_select option:selected")
        let fas = 0;
        let subscription = 0;
        options.each(function(){
            fas += parseFloat($(this).data("fas"));
            subscription += parseFloat($(this).data("subscription"));
        })

        $("#fas_options").text(I18n.toCurrency(fas));
        $("#subscription_options").text(I18n.toCurrency(subscription));

        let fas_total = parseFloat($("#fas_product").data("price")) + fas;
        let subscription_total = parseFloat($("#subscription_product").data("price")) + subscription;

        $("#fas_total").text(I18n.toCurrency(fas_total));
        $("#subscription_total").text(I18n.toCurrency(subscription_total));

        $("#summary_fas_options").text(I18n.toCurrency(fas));
        $("#summary_subscription_options").text(I18n.toCurrency(subscription));

        $("#summary_options").text(options.map(function(){return $(this).text()}).get().join(", "));
    }

    onInputChanged(e){
        let input = $(e.target);
        let value = input.val().trim();
        let id = input.attr("id");
        let target = $("#summary_"+id);

        if(target){
            if(value === "" || value == null){value = "-";console.log(value)}
            console.log(value);
            console.log("target", target);
            target.text(value);
        }
    }

    initValidation(){
        this.validation_client = new TemplateValidation(this.validationData(), "#quote_client_form", {ignore: []});
        this.validation_technical = new TemplateValidation(this.validationData(), "#quote_technical_form", {ignore: []});
    }

    validationData(){
        return {
            "order[customer_entity]": {
                required: true,
                minlength: 2
            },
            "order[customer_full_name]": {
                required: true,
                minlength: 2
            },
            "order[customer_email]": {
                email: true
            },
            "order[customer_phone]": {
                phone_number_fr: true
            },
            "order[coordinates]": {
            },
            "order[extra]": {
            },
            "order[collect_gate]": {
                required: true,
            },
            "order[collect_gate_b]": {
                required: true,
            },
            "order[svlan]": {
            },
            "order[delivery_interface]": {
                required: true,
            },
            "order[type_interface]": {
                required: true,
            },
            "order[commercial_partner_ref]": {
            }
        }
    }

    onSubmit(e){
        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    validateForm(e) {
        if($(e.target).attr("href") === "#technical_info"){
            if(!$("#quote_client_form").valid()){
                $("a[href='#client_info']").tab("show");
                return false;
            }
        }
        if($(e.target).attr("href") === "#summary"){
            if(!$("#quote_client_form").valid()){
                $("a[href='#client_info']").tab("show");
                return false;
            }
            if(!$("#quote_technical_form").valid()){
                $("a[href='#technical_info']").tab("show");
            }
        }
    };

    preventEnterKey(e) {
        if(e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    onDeliveryInterfaceChanged(e){
        let $el = $(this);
        let $typeInterface = $("#type_interface");
        let isOptical = $el.val() === "Optique";
        $typeInterface.prop("disabled", !isOptical);
        if(!isOptical){ $typeInterface.val(""); }
    }

    onSubmitQuote(e){
        let self = application.current_handler;
        let hasError = false;

        if(!$("input#cgu").is(":checked")) {
            hasError = true;
            $("label[for='cgu']").addClass("text-danger");
        }else{
            $("label[for='cgu']").removeClass("text-danger");
        }

        if(!$("input#cgp").is(":checked")) {
            hasError = true;
            $("label[for='cgp']").addClass("text-danger");
        }else{
            $("label[for='cgp']").removeClass("text-danger");
        }

        if(!hasError){
            application.openLoader();
            let fd = new FormData();
            $("input, select, textarea").each(function(){
                let $el = $(this);
                
                let name = $el.attr("name");
                let value = $el.val();
                if(name !== undefined && (name.startsWith("order") || name.startsWith("options"))){
                    fd.append(name, value);
                }
            });
            fd.append("product_id", application.getParam('product_id'));
            fd.append("request_id", application.getParam('request_id'));

            $.ajax({
                url: $("#quote_client_form").attr("action"),
                method: "POST",
                data: fd,
                processData: false,
                contentType: false,
                success: function(data){},
                error: function(data){
                    console.log(data);
                }
            })
        }
    }
}