import Routes from "./config/Routes";
import ConfigApplication from "../config/Application";
import ConfirmationModal from "./utils/ConfirmationModal";
import TemplateValidation from "./utils/TemplateValidation";
import IndexTemplate from "./utils/templates/IndexTemplate";

export default class Application extends ConfigApplication{
    constructor() {
        super();
        this.routes = new Routes();
        this.confirmationModal = new ConfirmationModal();
        this.datatable = null;
        this.exportdatatable = null;
        this.templateValidation = null;

        /* Templates JS */
        this.indexTemplate = null;

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        this.bindEvents()
    }
    pageChanged() {
        super.pageChanged();

        this.onAlertChange();
        this.onNoticeChange();
        this.onErrorChange();
        this.onSuccessChange();

        this.loadLibraries();
        this.setTemplateValidation(window.validationData);

        /* Templates JS */
        this.setIndexTemplate();
        this.openTab();
    }

    bindEvents(){
        super.bindEvents();
        let self = this;
        $(document).off('turbolinks:visit').on('turbolinks:visit', function (){
            if(self.templateValidation !== null){
                self.templateValidation.onDestroy();
                self.templateValidation = null;
            }
        })
    }


    bindEventsAfterPageChanged() {
        super.bindEventsAfterPageChanged();

        $('.js-delete-confirm').unbind('click').click(this.delete_confirm)
    }

    onAlertChange(){
        if(window.warning !== null && window.warning !== undefined && window.warning !== '') {
            this.alertNotification(window.warning);
        }
    }

    onNoticeChange(){
        if(window.notice !== null && window.notice !== undefined && window.notice !== '') {
            this.noticeNotification(window.notice);
        }
    }

    onErrorChange(){
        if(window.error !== null && window.error !== undefined && window.error !== '') {
            this.errorNotification(window.error);
        }
    }

    onSuccessChange(){
        if(window.success !== null && window.success !== undefined && window.success !== '') {
            this.successNotification(window.success);
        }
    }

    successNotification(msg){
        $.NotificationApp.send("Succès!",msg,"top-right","#5ba035","success",5000,4)
    }

    errorNotification(msg){
        $.NotificationApp.send("Erreur!",msg,"top-right","#bf441d","error",5000,4)
    }

    noticeNotification(msg){
        $.NotificationApp.send("Information!",msg,"top-right","#3b98b5","info",5000,4)
    }

    alertNotification(msg){
        $.NotificationApp.send("Attention!",msg,"top-right","#da8609","warning",5000,4)
    }

    delete_confirm() {
        let self = application
        let title = $(this).data("title");
        let message = $(this).data("message");
        let btnPositive = $(this).data("btn-confirm");
        let btnCancel = $(this).data("btn-cancel");
        let url = $(this).data("url");
        let id = $(this).data("id");
        self.confirmationModal.displayConfirm(
            title,
            message,
            {
                positive_btn: {
                    type: "danger",
                    label: btnPositive,
                    callback: function() {
                        let form = document.createElement('form');
                        form.style.visibility = 'hidden';
                        form.action = url;
                        form.method = 'post'

                        let input = document.createElement('input');
                        input.name = '_method';
                        input.value = 'delete';
                        input.type = 'hidden'
                        form.appendChild(input);

                        let inputToken = document.createElement('input');
                        inputToken.name = 'authenticity_token';
                        inputToken.value = $('meta[name="csrf-token"]').attr('content');
                        inputToken.type = 'hidden'
                        form.appendChild(inputToken);

                        document.body.appendChild(form);
                        form.submit();
                    }
                },
                negative_btn: {
                    label: btnCancel
                }
            });
    }

    loadLibraries() {
        let self = this;
        if(this.datatable !== null){
            this.datatable.destroy();
        }

        this.datatable = $('.datatable').DataTable({
            dom: 'rtp',
            responsive: false,
            language: require('../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                { orderable: false, searchable: false, targets: -1 }
            ]
        })


        let $search = $('#customSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.datatable.search($(this).val()).draw();
            })
        }

        if(this.exportdatatable !== null){
            this.exportdatatable.destroy();
        }
        this.exportdatatable = $('.exportdatatable').DataTable({
            language: require('../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            order: [[0, 'desc']],
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            dom: 'Bfrtip',
            buttons: [
                'csv',
                'pageLength'
            ]
        })
    }

    setTemplateValidation(validationData){
        if(validationData !== undefined){
            this.templateValidation = new TemplateValidation(validationData);
        }
    }

    setIndexTemplate(){
        if(this.indexTemplate !== undefined){
            this.indexTemplate = new IndexTemplate();
        }
        this.indexTemplate.onPageChanged();
    }

    openTab() {
        let url = document.location.toString();
        if (url.match('#')) {
            $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
        }
    }

    openLoader(){
        $("#default_loader").modal("show");
    }

    closeLoader(){
        $("#default_loader").modal("hide");
    }

    getParam(param){
        let url = new URL(window.location.href);
        return url.searchParams.get(param);
    }
}