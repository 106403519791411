import CommentsAndFilesSection from '../utils/CommentsAndFilesSection';
export default class ExtranetOrdersShow {

  constructor() {
    this.bindEvents();
    this.sections = new CommentsAndFilesSection();
}


bindEvents() {
}

onDataChanged(data) {
}

onDestroy() {
}
}