import TemplateValidation from "../utils/TemplateValidation";

export default class ExtranetEligibilityIndex {

    constructor() {
        this.fttoDataTable = null;
        this.fonDataTable = null;

        this.placesAddress = null;
        this.placesAddressA = null;
        this.placesAddressB = null;
        
        this.testValidation = null;
        this.fonExportValidation = null;
        this.fttoExportValidation = null;

        this.bindEvents();

        this.setDatatables();
        this.initAutocompletePlaces();
        this.initValidation();
        this.toggleFttoFon();
        this.toggleTestModal();
    }


    bindEvents() {
        $("#nexloop_solution").change(this.onSolutionChange)
        $("#eligibility_request").submit(this.onSubmitTestEligibility)
        $(".js-export-fon").click(this.onExportFon)
        $(".js-export-ftto").click(this.onExportFtto)
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.fttoDataTable.destroy();
        this.fttoDataTable = null;
        this.fonDataTable.destroy();
        this.fonDataTable = null;

        $("#nexloop_solution").off("change", this.onSolutionChange)
        $("#onSubmitTestEligibility").off("submit", this.onSubmitTestEligibility)
        $(".js-export-fon").off("click", this.onExportFon)
        $(".js-export-ftto").off("click", this.onExportFtto)

        if(this.testValidation !== null){
            this.testValidation.onDestroy();
            this.testValidation = null;
        }

        if(this.fonExportValidation !== null){
            this.fonExportValidation.onDestroy();
            this.fonExportValidation = null;
        }

        if(this.fttoExportValidation !== null){
            this.fttoExportValidation.onDestroy();
            this.fttoExportValidation = null;
        }
    }

    setDatatables() {
        this.initFttoDatatable();
        this.initFonDatatable();
    }


    initFttoDatatable(){
        let self = this;

        let $el = $('#ftto_datatable')
        if($el.length <= 0){ return false }

        this.fttoDataTable = $el.DataTable({
            dom: 'rtp',
            responsive: false,
            serverSide: true,
            "pageLength": 20,
            order: [[0, 'desc']],
            language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                { orderable: false, searchable: false, targets: -1 },
                { orderable: false, searchable: false, targets: 2 },
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    //data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "address" },
                { "data": "coordinates", "orderable": false },
                { "data": "result" },
                { "data": "result_one" },
                { "data": "actions", "orderable": false },
            ],
            /*drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }*/
        })


        let $search = $('#customFttoSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.fttoDataTable.search($(this).val()).draw();
            })
        }
    }


    initFonDatatable(){
        let self = this;

        let $el = $('#fon_datatable')
        if($el.length <= 0){ return false }

        this.fonDataTable = $el.DataTable({
            dom: 'rtp',
            serverSide: true,
            responsive: false,
            "pageLength": 20,
            order: [[0, 'desc']],
            language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                { orderable: false, searchable: false, targets: -1 },
                { orderable: false, searchable: false, targets: 2 },
                { orderable: false, searchable: false, targets: 4 },
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    //data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "address" },
                { "data": "coordinates", "orderable": false },
                { "data": "secondary_address" },
                { "data": "secondary_coordinates", "orderable": false },
                { "data": "result" },
                { "data": "actions", "orderable": false },
            ],
            /*drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }*/
        })


        let $search = $('#customFonSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.fonDataTable.search($(this).val()).draw();
            })
        }
    }

    onSolutionChange(e) {
        let self = application.current_handler;
        self.toggleFttoFon();
    }

    toggleFttoFon(){
        let $el = $("#nexloop_solution")
        let $container = $("#eligibility_container")
        if($el.val() === "ftto") {
            $container.removeClass("is_fon")
        } else {
            $container.addClass("is_fon")
        }
    }

    toggleTestModal(){
        if(application.getParam("modal_test") === "true"){
            $("#test_modal").modal("show")
        }
    }


    initAutocompletePlaces() {

        //autocomplete options
        const options = {
            componentRestrictions: { country: "fr" },
            types: ['address'],
        };
        //autocomplete offer 1
        this.placesAddress = new google.maps.places.Autocomplete(document.getElementById("address"), options);

        //autocomplete offer2
        this.placesAddressA = new google.maps.places.Autocomplete(document.getElementById("address_a"), options);
        this.placesAddressB = new google.maps.places.Autocomplete(document.getElementById("address_b"), options);
    }

    onSubmitTestEligibility(e){
        e.preventDefault();
        let self = application.current_handler;
        let $el = $(e.currentTarget)

        if(!$el.valid()){ return false; }
        application.openLoader();
        let data = {
            address: $("#address").val(),
            address_a: $("#address_a").val(),
            address_b: $("#address_b").val(),
            solution: $("#nexloop_solution").val(),
        };

        let ajax_data = {
            url: $el.data("url"),
            data: data,
            method: "POST",
            success: self.testEligibilitySuccess,
            error: self.testEligibilityError
        }

        $.ajax(ajax_data)
    }

    testEligibilitySuccess(data){
        application.closeLoader();
        Turbolinks.visit(data.redirect_url)
    }

    testEligibilityError(data){
        application.closeLoader();
        if(data.status === 404){
            $("#info_modal").modal("show")
            $("#info_modal #infoModalTitle").html(I18n.t("extranet.eligibility.index.test_eligibility_result.no_token_title"))
            $("#info_modal #infoModalContent").html(I18n.t("extranet.eligibility.index.test_eligibility_result.no_token_message"))
        }else{
            $("#info_modal").modal("show")
            $("#info_modal #infoModalTitle").html(I18n.t("extranet.eligibility.index.test_eligibility_result.request_error_title"))
            $("#info_modal #infoModalContent").html(data.responseJSON.error)
        }
    }

    initValidation() {
        this.testValidation = new TemplateValidation(this.validationUpdateData(), "#eligibility_request");
        this.fonExportValidation = new TemplateValidation(this.validationFonExportData(), "#export_fon_form");
        this.fttoExportValidation = new TemplateValidation(this.validationFttoExportData(), "#export_ftto_form");
    }

    validationUpdateData() {
        return {
            "address": {
                required: true
            },
            "address_a": {
                required: true
            },
            "address_b": {
                required: true
            },
            "nexloop_solution": {
                required: true
            }
        };
    }

    validationFonExportData() {
        return {
            "export_fon_start_date": {
                required: true
            },
            "export_fon_end_date": {
                required: true,
                greater_than: "#export_fon_start_date"
            }
        };
    }

    validationFttoExportData() {
        return {
            "export_ftto_start_date": {
                required: true
            },
            "export_ftto_end_date": {
                required: true,
                greater_than: "#export_ftto_start_date"
            }
        };
    }

    onExportFon(e){
        e.preventDefault();
        let $el = $(e.currentTarget)
        let self = application.current_handler;

        if(!$("#export_fon_form").valid()){ return false; }

        $("#export_fon_modal").modal("hide")

        self.exportRequests(
            $el.data("url"),
            $("#export_fon_start_date").val(),
            $("#export_fon_end_date").val()
        );
    }

    onExportFtto(e){
        e.preventDefault();
        let $el = $(e.currentTarget)
        let self = application.current_handler;

        if(!$("#export_ftto_form").valid()){ return false; }

        $("#export_ftto_modal").modal("hide")

        self.exportRequests(
            $el.data("url"),
            $("#export_ftto_start_date").val(),
            $("#export_ftto_end_date").val()
        );
    }

    exportRequests(url, start_date, end_date){
        application.openLoader();

        $.ajax({
            url: url,
            method: "POST",
            data: {
                start_date: start_date,
                end_date: end_date
            },
            success: function (data){
                application.closeLoader();
                window.open(data.url, '_blank')
            }
        })
    }
}