export default class ExtranetOrdersIndex {
  constructor() {
    this.bindEvents();
    this.initDatatable();
  }

  bindEvents() {
    this.step_filter = $("#step-filter").select2({
      multiple: true,
      placeholder: "Selectionner des états",
      allowClear: true,
      closeOnSelect: false
    })

    $("#step-filter").val("").trigger('change');
  }

  initDatatable() {
    this.table = $("#orders-datatable").DataTable({
      "responsive": true,
      "searching": true,
      dom: 'rtp',
      "columnDefs": [{
        "targets": -1,
        "orderable": false
      }],
      "order": [[1, "desc"]],
      "language": {
        url: '/assets/data-tables/locales/' + I18n.locale + '.json',
        previous: "<i class='mdi mdi-chevron-left'>",
        next: "<i class='mdi mdi-chevron-right>"
      },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      drawCallback:function(){
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
      }
    });

    this.initFilters(this.table);
  }

  initFilters(table) {
    let self = this;

    $("#step-filter").change(function() {
      var selected = $(this).val();
      console.log(selected);
      self.table.column(5).search(selected.join('|'), true, false).draw();
    });

    $("#customSearch").on('keyup', function() {
      self.table.search($(this).val()).draw();
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    this.step_filter.select2('destroy');
    this.table.destroy();
  }
}