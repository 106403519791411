export default class ScrollPagination {

  constructor( listContainer = "#product_list", listItems = "[id^=product_]", data = {}, resetGridEvents = () => {}) {

    this.scrollContainer = document;
    this.scrollWindow = window;
    this.listContainer = listContainer;
    this.listItems = listItems;

    this.page = 1;
    this.is_reset = true;
    this.timeout = null;
    this.data = {};
    this.orderFilter = null;

    this.resetGridEvents = resetGridEvents

    this.setUpSelfMethods()

    this.bindEvents();
    this.resetGrid( data );
}


bindEvents() {
}

onDestroy() {
    $(this.scrollContainer).unbind('scroll', this.onScroll);
}

setUpSelfMethods() {
    let self = this
    this.onScroll = (e) => { self.load_page_if_visible() }
    this.ajaxIndexSuccess = (html) => { self.ajaxIndexSuccessMethod(html) }
}

resetEvents(){
    this.resetGridEvents();
}


/** ----------- START Ajax Search -----------  **/
onSearchKeyup( data = {} ){
    let self = this;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function (){
        self.resetGrid(data);
    }, 500)
}

onStatusChange( data = {} ){
    this.resetGrid(data);
}
/** ----------- START Ajax Search -----------  **/


/** ----------- START Ajax Index Grid -----------  **/
resetGrid(data = {}){
    this.is_reset = true
    this.page = 1;
    this.data = data
    this.ajax_render_grid();
}

ajax_render_grid(){
    $(this.scrollContainer).off('scroll', this.onScroll);

    $.ajax({
        url: $(this.listContainer).data("url"),
        data: this.ajaxIndexData(),
        success: this.ajaxIndexSuccess,
        error: this.ajaxIndexError,
    });
}

ajaxIndexData(){
    return Object.assign({}, this.data, {
        page: this.page
    });
}

ajaxIndexSuccessMethod(html){
    if(this.is_reset){
        this.is_reset = false
        $(this.listContainer).html("");
    }

    let before = $(this.listItems).length;
    $(this.listContainer).append(html);
    let after = $(this.listItems).length;

    if(before !== after){ $(this.scrollContainer).on('scroll', this.onScroll); } // Scroll event

    this.page++;
    this.resetEvents();
}

ajaxIndexError(data){
    console.log(data);
}
/** ----------- END Ajax Index Grid -----------  **/


/** ----------- START Scroll -----------  **/

load_page_if_visible(){
    let $el = $(".js-reload");
    let $container = $(this.scrollWindow);

    let top_of_element = $el.offset().top;
    let bottom_of_element = $el.offset().top + $el.outerHeight();
    let bottom_of_screen = $container.scrollTop() + $container.innerHeight();
    let top_of_screen = $container.scrollTop();

    if ( (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) ) {
        this.ajax_render_grid();
    }
}
/** ----------- END Scroll -----------  **/
}