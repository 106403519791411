export default class ExtranetIncidentsEdit {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.dropify = $('#file').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
                'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
            }});

        $('#send_comment_incident_form').submit((e) => {
            if($('#text_comment').val().trim() === ""){
                e.preventDefault()
                return false;
            }

            application.openLoader();
        })
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }
}