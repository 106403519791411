import TemplateValidation from "../utils/TemplateValidation";

export default class ExtranetOrganizationCustomersForm {

    constructor() {
        this.updateValidation = null;

        this.bindEvents();
        this.initValidation();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        if(this.updateValidation !== null){
            this.updateValidation.onDestroy();
            this.updateValidation = null;
        }
    }


    initValidation() {
        this.updateValidation = new TemplateValidation(this.validationUpdateData(), "#customer_form");
    }

    validationUpdateData() {
        return {
            "customer[first_name]": {
                required: true,
                minlength: 2
            },
            "customer[last_name]": {
                required: true,
                minlength: 2
            },
            "customer[email]": {
                required: true,
                email_validation: true
            },
            "customer[phone]": {
                required: true,
                phone_number_fr: true
            }
        };
    }
}