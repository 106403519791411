export default class ExtranetEligibilityCoverageMap {

    constructor() {
        this.map = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
        this.initMap()
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }


    initMap() {
      let self = this;
      this.map = new google.maps.Map(document.getElementById('coverage_map'), {
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: [
              {
                  featureType: "poi",
                  stylers: [{ visibility: "off" }],
              },
              {
                  featureType: "administrative.neighborhood",
                  stylers: [{ visibility: "off" }],
              },
              {
                  featureType: "transit",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }],
              },
          ],
          gestureHandling: "scroll"
      });
      google.maps.event.addListenerOnce(this.map, 'idle', function(){
          self.map.data.loadGeoJson(
              $("#coverage_map").data("geojson-url")
          );
  
          self.map.data.setStyle(function(feature) {
            return {
                fillColor: '#019ca6',
                strokeWeight: 1,
                strokeColor: '#007d85',
            };
          });
  
          // zoom on IDF
          let parisBounds = new google.maps.LatLngBounds();
          parisBounds.extend(new google.maps.LatLng(48.8566, 2.3522));
          
          self.map.setCenter(parisBounds.getCenter());
          self.map.fitBounds(parisBounds);
          self.map.setZoom(9);
      });
  }
  

    checkForGeoJson() {
        $.ajax({
            url: $("#coverage_map").data("geojson-url"),
            success: this.successCheckForGeoJson,
            error: (data) => {},
        })
    }

    successCheckForGeoJson(data){

    }
}