export default class CustomersIndex {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $("input[id^='customer_status_']").on("change", (e) => {
            let is_enabled = !!$(e.currentTarget).is(":checked");
            let url = $(e.currentTarget).data("url");
            $.ajax({
                url: url,
                method: "PUT",
                data: {
                    customer: {
                        is_enabled: is_enabled
                    }
                },
                success: (data) => {
                    window.application.successNotification(data.msg);
                },
                error: (data) => {
                    console.log("error", data);
                    window.application.errorNotification(data.msg);
                }
            });
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }
}