import TemplateValidation from "../../utils/TemplateValidation";

export default class ProfileForm {

    constructor() {
        this.updateValidation = null;
        this.updatePasswordValidation = null;

        this.bindEvents();
        this.initValidation();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        if(this.updateValidation !== null){
            this.updateValidation.onDestroy();
            this.updateValidation = null;
        }
        if(this.updatePasswordValidation !== null){
            this.updatePasswordValidation.onDestroy();
            this.updatePasswordValidation = null;
        }
    }

    initValidation() {
        this.updateValidation = new TemplateValidation(this.validationUpdateData(), "#profile_form");
        this.updatePasswordValidation = new TemplateValidation(this.validationUpdatePasswordData(), "#profile_password_form");
    }

    validationUpdateData() {
        return {
            "customer[first_name]": {
                required: true,
                minlength: 2
            },
            "customer[last_name]": {
                required: true,
                minlength: 2
            },
            "customer[email]": {
                required: true,
                email_validation: true
            },
            "customer[phone]": {
                required: true,
                phone_number_fr: true
            }
        };
    }

    validationUpdatePasswordData() {
        return {
            "customer[current_password]": {
                required: true,
            },
            "customer[password]": {
                required: true,
                minlength: 6,
                maxlength: 128,
            },
            "customer[password_confirmation]": {
                required: true,
                minlength: 6,
                maxlength: 128,
            }
        };
    }
}