import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/ExtranetDashboardIndex";
import ExtranetOrganizationIndex from "../controllers/ExtranetOrganizationIndex";
import ExtranetOrganizationCustomersForm from "../controllers/ExtranetOrganizationCustomersForm";
import ExtranetEligibilityIndex from "../controllers/ExtranetEligibilityIndex";
import ExtranetEligibilityShow from "../controllers/ExtranetEligibilityShow";
import ExtranetEligibilityCoverageMap from "../controllers/ExtranetEligibilityCoverageMap";
import ExtranetQuotesNew from "../controllers/ExtranetQuotesNew";
import ExtranetInvoicesIndex from "../controllers/ExtranetInvoicesIndex";
import ExtranetIncidentsIndex from "../controllers/ExtranetIncidentsIndex";
import ExtranetIncidentsNew from "../controllers/ExtranetIncidentsNew";
import ExtranetIncidentsEdit from "../controllers/ExtranetIncidentsEdit";
import ExtranetOrdersNew from "../controllers/ExtranetOrdersNew";
import ExtranetSharedFilesNew from "../controllers/ExtranetSharedFilesNew";
import ExtranetSharedFilesIndex from "../controllers/ExtranetSharedFilesIndex";
import ExtranetOrdersShow from "../controllers/ExtranetOrdersShow";
import ExtranetStudyRequestsShow from "../controllers/ExtranetStudyRequestsShow";
import ExtranetOrdersIndex from "../controllers/ExtranetOrdersIndex";
import ExtranetStudyRequestsIndex from "../controllers/ExtranetStudyRequestsIndex";

export default class Routes extends ConfigRoutes{

    static routes = {
        "DashboardIndex": DashboardIndex,
        "ExtranetOrganizationProfileUpdate": ExtranetOrganizationIndex,
        "ExtranetOrganizationProfileUpdatePassword": ExtranetOrganizationIndex,
        "ExtranetOrganizationIndex": ExtranetOrganizationIndex,
        "ExtranetOrganizationCustomersNew": ExtranetOrganizationCustomersForm,
        "ExtranetOrganizationCustomersCreate": ExtranetOrganizationCustomersForm,
        "ExtranetOrganizationCustomersEdit": ExtranetOrganizationCustomersForm,
        "ExtranetOrganizationCustomersUpdate": ExtranetOrganizationCustomersForm,
        "ExtranetEligibilityIndex": ExtranetEligibilityIndex,
        "ExtranetEligibilityShow": ExtranetEligibilityShow,
        "ExtranetEligibilityCoverageMap": ExtranetEligibilityCoverageMap,
        "ExtranetQuotesNew": ExtranetQuotesNew,
        "ExtranetInvoicesIndex": ExtranetInvoicesIndex,
        "ExtranetIncidentsIndex": ExtranetIncidentsIndex,
        "ExtranetIncidentsNew": ExtranetIncidentsNew,
        "ExtranetIncidentsCreate": ExtranetIncidentsNew,
        "ExtranetIncidentsEdit": ExtranetIncidentsEdit,
        "ExtranetOrdersNew": ExtranetOrdersNew,
        "ExtranetSharedFilesNew": ExtranetSharedFilesNew,
        "ExtranetSharedFilesIndex": ExtranetSharedFilesIndex,
        "ExtranetOrdersShow": ExtranetOrdersShow,
        "ExtranetStudyRequestsShow": ExtranetStudyRequestsShow,
        "ExtranetOrdersIndex": ExtranetOrdersIndex,
        "ExtranetStudyRequestsIndex": ExtranetStudyRequestsIndex
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}
