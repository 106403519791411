import ProfileForm from "./organization/ProfileForm";
import CustomersIndex from "./organization/CustomersIndex";
import RolesIndex from "./organization/RolesIndex";

export default class ExtranetOrganizationIndex {

    constructor() {
        this.profile = new ProfileForm()
        this.customers = new CustomersIndex()
        this.roles = new RolesIndex()

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.profile.onDestroy()
        this.customers.onDestroy()
        this.customers.onDestroy()
    }
}