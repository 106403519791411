export default class ExtranetIncidentsIndex {
  constructor() {
      this.datatable = null;

      this.crit_select = $(".crit-select").selectpicker({ noneSelectedText: "Criticitée" });
      this.cat_select = $(".cat-select").selectpicker({ noneSelectedText: "Catégorie" });
      this.status_select = $(".status-select").selectpicker({ noneSelectedText: "Statut" });

      this.initDatatable();
      this.bindEvents();
  }


  bindEvents() {
    const self = this;
    
    const $el = $('#incidents-datatable');

    $("#maj_date_1, #maj_date_2, #c_date_1, #c_date_2").datetimepicker({
      locale: "fr",
      format: "DD/MM/YYYY",
      icons: {
        time: "fas fa-clock",
      },
    }).on('change.datetimepicker', function () {
      self.datatable.draw();
    });
    
    $el.DataTable.ext.search.push(function (settings, data, dataIndex) {

      const crit = self.crit_select.val();
      const cat = self.cat_select.val();
      const status = self.status_select.val();

      const c_date_1 = $("#c_date_1").datetimepicker("date");
      const c_date_2 = $("#c_date_2").datetimepicker("date");

      const maj_date_1 = $("#maj_date_1").datetimepicker("date");
      const maj_date_2 = $("#maj_date_2").datetimepicker("date");
  
      if ((crit.length === 0 && cat.length === 0 && status.length === 0 && maj_date_1 === null && maj_date_2 === null && c_date_1 === null && c_date_2 === null)) {
        return true;
      }
  
      let critMatch = false;
      let catMatch = false;
      let statusMatch = false;
      let majDateMatch = false;
      let cDateMatch = false;
  
      if (crit.length === 0) {
        critMatch = true;
      } else {
        critMatch = crit.includes(data[1]);
      }
  
      if (cat.length === 0) {
        catMatch = true;
      } else {
        catMatch = cat.includes(data[2]);
      }
  
      if (status.length === 0) {
        statusMatch = true;
      } else {
        statusMatch = status.includes(data[6]);
      }

      if (c_date_1 === null && c_date_2 === null) {
        cDateMatch = true;
      } else {
        const c_date = moment(data[4], "DD/MM/YYYY");
        if (c_date_1 !== null) {
          cDateMatch = c_date.isSameOrAfter(c_date_1);
        }
        if (c_date_2 !== null) {
          cDateMatch = c_date.isSameOrBefore(c_date_2);
        }

        if (c_date_1 !== null && c_date_2 !== null && c_date_2.isSameOrAfter(c_date_1)) {
          cDateMatch = c_date.isBetween(c_date_1, c_date_2);
        }
      }      
  
      if (maj_date_1 === null && maj_date_2 === null) {
        majDateMatch = true;
      } else {
        const maj_date = moment(data[5], "DD/MM/YYYY");
        if (maj_date_1 !== null) {
          majDateMatch = maj_date.isSameOrAfter(maj_date_1);
        }
        if (maj_date_2 !== null) {
          majDateMatch = maj_date.isSameOrBefore(maj_date_2);
        }

        if (maj_date_1 !== null && maj_date_2 !== null && maj_date_2.isSameOrAfter(maj_date_1)) {
          majDateMatch = maj_date.isBetween(maj_date_1, maj_date_2);
        }
      }

      return critMatch && catMatch && statusMatch && cDateMatch && majDateMatch;
    });
  
    $(".crit-select, .cat-select, .status-select").on('changed.bs.select', function () {
      self.datatable.draw();
    });
  }
  
  initDatatable() {
    const self = this;
    let $el = $('#incidents-datatable');
    this.datatable = $el.DataTable({
      dom: 'rtp',
      responsive: false,
      pageLength: 20,
      order: [[0, 'desc']],
      language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json')
    });
  
    let $search = $('#customSearch');
  
    if ($search.length !== 0) {
      $search.keyup(function() {
        self.datatable.search($(this).val()).draw();
      });
    }
  }

  onDataChanged(data) {
  }

  onDestroy() {
    this.datatable.destroy();

    this.crit_select.selectpicker('destroy');

    this.cat_select.selectpicker('destroy');

    this.status_select.selectpicker('destroy');
    
    $("#maj_date_1, #maj_date_2, #c_date_1, #c_date_2").datetimepicker("destroy");
  }
}