export default class ExtranetSharedFilesNew {

  constructor() {
      this.bindEvents();
  }


  bindEvents() {
      $('#file').dropify({
        messages: {
            'default': I18n.t('plugins.dropify.messages.default'),
            'replace': I18n.t('plugins.dropify.messages.replace'),
            'remove':  I18n.t('plugins.dropify.messages.remove'),
            'error':   I18n.t('plugins.dropify.messages.error')
        },
        error: {
            'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
            'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
            'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
            'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
            'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
            'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
            'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
        }});  
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $('#file').dropify("destroy");
  }
}