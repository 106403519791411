export default class ExtranetStudyRequestsIndex {
  constructor() {
    this.bindEvents();
    this.initDatatable();
  }

  bindEvents() {

  }

  initDatatable() {
    this.table = $("#sr-datatable").DataTable({
      "responsive": true,
      "searching": true,
      dom: 'rtp',
      "columnDefs": [{
        "targets": -1,
        "orderable": false
      }],
      "order": [[1, "desc"]],
      "language": {
        url: '/assets/data-tables/locales/' + I18n.locale + '.json',
        previous: "<i class='mdi mdi-chevron-left'>",
        next: "<i class='mdi mdi-chevron-right>"
      },
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      drawCallback:function(){
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
      }
    });
  }
  
  onDataChanged(data) {
  }

  onDestroy() {
    this.table.destroy();
  }
}